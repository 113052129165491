import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/global/seo';
import InfoboxFragmentWrapper from '../components/global/InfoboxFragmentWrapper/InfoboxFragmentWrapper';
import CustomerStoryFragmentWrapper from '../components/global/CustomerStoryFragmentWrapper/CustomerStoryFragmentWrapper';
import CTAWrapper from '../components/home-page/CtaWrapper/CTAWrapper';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import Layout from '../components/global/layout/Layout';
import InfoCardsFragmentWrapper from '../components/global/InfoCardsFragmentWrapper/InfoCardsFragmentWrapper';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import InfoCard from '../components/global/InfoCard/InfoCard.component';
import { Container } from '../components/global/customers/styles';
import { SliderContainer } from '../components/customer-support/styles';
import { InfoCardGrid } from '../components/global/InfoCard/InfoCard.styled';

const FaqBuilderTemplate = ({ data }) => {
	const {
		hero,
		infoSection1,
		customerTestimonial,
		infoSection2,
		additionalCardSectionTitle,
		additionalCards,
		cta,
		seo,
	} = data.contentfulPageFeaturePage;
	return (
		<>
			<Layout>
				<SEO {...seo} />
				<InfoboxFragmentWrapper data={hero} />
				<Container>
					<InfoCardsFragmentWrapper data={infoSection1} />
					<CustomerStoryFragmentWrapper data={customerTestimonial} />
					<InfoCardsFragmentWrapper data={infoSection2} />
					<SliderContainer>
						<SectionTextBox
							title={additionalCardSectionTitle}
							largeHeading="true"
						/>
					</SliderContainer>
					<InfoCardGrid style={{ marginTop: '20px' }}>
						{additionalCards.map(card => (
							<InfoCard
								image={card.icon}
								title={card.title}
								subtile={card.description.description}
								linkText={card.linkText}
								linkUrl={card.linkUrl}
							/>
						))}
					</InfoCardGrid>
					<CTAWrapper>
						<MasterCTA cta={cta} />
					</CTAWrapper>
				</Container>
			</Layout>
		</>
	);
};

export const pageQuery = graphql`
	query($slug: String) {
		contentfulPageFeaturePage(slug: { eq: $slug }) {
			hero {
				...infoSectionFragment
			}
			infoSection1 {
				...infoSectionFragment
			}
			customerTestimonial {
				...customerTestimonial
			}
			infoSection2 {
				...infoSectionFragment
			}
			additionalCardSectionTitle
			additionalCards {
				...doMore
			}
			cta {
				...cta
			}
			seo {
				...seo
			}
		}
	}
`;

export default FaqBuilderTemplate;
